/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap'); */
@import 'react-responsive-carousel/lib/styles/carousel.min.css';

@font-face {
  font-family: 'MainFont';
  src: url(./assets/fonts/RoobertTrial.ttf);
}

body {
  margin: 0;
  font-family:
    'MainFont',
    'Manrope',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grecaptcha-badge {
  display: none !important;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

#root {
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  background-color: #00195a;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#ik-identity-kit-modal {
  z-index: 9999999;
}